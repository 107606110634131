(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:PhaseMatchDates
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('PhaseMatchDates', PhaseMatchDates);

  function PhaseMatchDates($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/phases/:phaseId/matchDates', {phaseId: '@_phaseId'});
  }
}());
